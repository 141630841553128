import React from 'react';

// Components
import { HeroSecondary, EmptyHero } from 'components/core/hero';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

export function Hero(){
  const {t} = useTranslation("case-study");
  return <HeroSecondary title={t("title")}/>
}

export function CaseHero({title}){
  return <HeroSecondary title={title}/>
}
export {EmptyHero};
