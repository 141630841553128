/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Summary} = _components;
  if (!Summary) _missingMdxReference("Summary", true);
  return React.createElement(React.Fragment, null, React.createElement(Summary, null, "Nous recherchons un(e) développeur(se) backend pour travailler sur le développement de notre produit Hectiq Lab et contribuer aux livrables du service de consultation en intelligence artificielle (iA)."), "\n", React.createElement(_components.h2, null, "Vos responsabilités"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Mettre en production les méthodes développées par les Scientifiques de Données"), "\n", React.createElement(_components.li, null, "Participer à bâtir les outils internes de la compagnie"), "\n", React.createElement(_components.li, null, "Concevoir des interfaces (UX) de qualité pour interagir avec les modèles et analyses"), "\n", React.createElement(_components.li, null, "Mettre en place et maintenir l'infrastructure cloud (AWS / Google Cloud)"), "\n"), "\n", React.createElement(_components.h2, null, "Profil recherché"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Bonne connaissance de Python et React"), "\n", React.createElement(_components.li, null, "Soucieux(se) de produire du travail de haute qualité et suivre les bonnes pratiques de programmation"), "\n", React.createElement(_components.li, null, "Expérience en développement et déploiement de logiciels backends"), "\n", React.createElement(_components.li, null, "Une expertise avec les bases de données relationnelles (SQL)"), "\n", React.createElement(_components.li, null, "Capable d'exprimer clairement ses idées à l'oral et à l'écrit"), "\n", React.createElement(_components.li, null, "Atout: Expérience en développement d'applications iOS & Android"), "\n", React.createElement(_components.li, null, "Atout: Être familier avec AWS et/ou Google Cloud"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
